import React from "react";
import { Router, Route } from "@reach/router";
import PrivateRoute from "../components/PrivateRoute";
import Dashboard from "../components/dashboard";
import PasswordUpdate from "../components/password-update";
import Memberships from "../components/memberships";

const Profile = () => (
  <div>Hi</div>
    // <Router>
    //   <PrivateRoute exact path="/profile/" component={Dashboard}/>
    //   <PrivateRoute path="/profile/password-update" component={PasswordUpdate}/>
    //   <PrivateRoute path="/profile/memberships" component={Memberships}/>
    // </Router>
)
export default Profile;